import React from 'react';
import playBtn from 'assets/icons/play-white.svg';
import pauseBtn from 'assets/icons/pause-white.svg';
import './PlayerButton.scss';

const PlayerButtonMain = ({ size = 40, url, playing, focused, onClick, className = '', name }) => {
  return (
    <div onClick={onClick} className={className || ''} style={{ width: size, height: size }}>
      <div className='player-control__box'>
        {focused && !playing && (
          <img
            src={playBtn}
            className='player-control play-btn play-btn_main'
            style={{ left: 5, width: 28 }}
            alt='play button'
          />
        )}
        {focused && playing && <img src={pauseBtn} className='player-control' alt='pause button' />}
      </div>
    </div>
  );
};

export default PlayerButtonMain;
