import React, { useEffect, useRef, useState } from 'react';
import {LogoHeader} from '../icons/logo-header';
import Logo from '../logo/Logo';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../interfaces/app-state';
import { toggleTopMenu } from '../../../redux/settings/actions';
import className from '../../../utils/class-names';
import './Header.scss';
import { DesktopNav } from './DesktopNav';
import { MobileNav } from './MobileNav';
import { useLocation } from 'hooks/useLocation';
import { NavLink } from 'layout/components/nav-link';
import { useGetSideBarStructureQuery } from '../../../redux/api/side-bar-structure';
import { useIsBlackFridayBannerVisible } from '../../../redux/pricing-info/selectors';
import { useIsBannerVisible, useLanguage } from 'hooks';
import { Button } from '../app-button';
import { useMediaQuery } from 'react-responsive';
import useTranslation from 'hooks/useTranslations';
import { usePush } from 'hooks/usePush';

type HeaderProps = {
  onlyLogo?: boolean;
};

export default function Header({ onlyLogo }: HeaderProps) {
  const [scrolled, setScrolled] = useState(false);
  const { search, pathname } = useLocation();
  const [isBottomReached, setIsBottomReached] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const dispatch = useDispatch();
  const settings = useSelector((state: IAppState) => state.settings);
  const loggedIn = useSelector<IAppState, object>((state) => state.loggedIn);
  const language = useLanguage()
  const { data: renderMenu = [] } = useGetSideBarStructureQuery(language);
  const isRenderRoute = pathname.startsWith('/render');
  const isBlackFridayVisible = useIsBlackFridayBannerVisible();
  const isMobile = useMediaQuery({ maxWidth: 543 });
  const push = usePush();

  const redirectToBlackFridayPage = () => {
    push('/render/pricing?tab=holiday-deal');
  };

  const headerRef = useRef(null);
  useEffect(() => {
    let timeout;
    if (settings.topMenuOpened) {
      setIsMenuVisible(true);
      document.documentElement.style.overflow = 'hidden';
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    } else {
      document.documentElement.style.overflow = 'auto';
      timeout = setTimeout(() => {
        if (!search || !search.includes('scrollTo=')) {
          if (headerRef.current) {
            headerRef.current.scrollTop = 0;
          }
        }
        setIsMenuVisible(false);
        timeout = null;
      }, 500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    };
  }, [settings.topMenuOpened]);

  const onToggleTopMenu = () => {
    dispatch(toggleTopMenu());
  };

  const handleContentScroll = (event) => {
    setScrolled(event.target.scrollTop > 0);
    const finalScrollPos = event.target.scrollHeight - event.target.clientHeight;

    setIsBottomReached(Math.abs(finalScrollPos - event.target.scrollTop) <= 10);
  };

  const isWithSubscriptions = Boolean(
    loggedIn && loggedIn['subs'] && Object.keys(loggedIn['subs']).filter(key => key !== 'ambassador').length
  );
  const showPremium = !isWithSubscriptions && (loggedIn || isRenderRoute);

  const classes = className({
    'app-header': true,
    'app-header_opened': settings.topMenuOpened,
    'app-header_scrolled': scrolled,
    'app-header_bottom-reached': isBottomReached
  });

  const isBannerVisible = useIsBannerVisible();

  const blackFridayClassNames = className({
    'app-header__black-friday': true,
    'app-header__black-friday_hidden': isMenuVisible
  });

  const translate = useTranslation();

  return (
    <div className={classes}>
      {onlyLogo ? (
        <>
          <div className='app-header__header'>
            {/* <Logo height={30} width={119} src={LogoSvg} /> */}
            <Logo>
              <LogoHeader height={30} width={119} />
            </Logo>
          </div>
          <NavLink to='/render/pricing?tab=holiday-deal'>
            <div className='app-header__black-friday'>
              {isMobile ? (
                <div className='app-header__black-friday-mobile'>
                  <div style={{ fontSize: 13, fontWeight: 800 }}>33% off on all plans!</div>
                  <div style={{ fontSize: 18, fontWeight: 700, marginTop: -5 }}>
                    Black Friday Deal
                  </div>
                  <div style={{ fontSize: 10, fontWeight: 500, marginTop: -5 }}>
                    Secure the discounted rate for up to 3 months
                  </div>
                </div>
              ) : (
                <span>
                  <b>Black Friday Deal</b> - 33% off on all plans! Secure the discounted rate for up
                  to 3 months
                </span>
              )}
              <div className='app-header__black-friday-button'>
                <Button onClick={redirectToBlackFridayPage}>{translate("Get started")}</Button>
              </div>
            </div>
          </NavLink>
        </>
      ) : (
        <>
          <div className={'app-header__header'}>
            {/* <Logo height={24} width={92} src={LogoSvg} /> */}
            <Logo>
              <LogoHeader  height={30} width={90} />
            </Logo>
            <div className='app-header__nav-container'>
              <DesktopNav onToggleTopMenu={onToggleTopMenu} settings={settings} />
            </div>
          </div>
          {isBannerVisible && (
            <a
              href='https://mubert.getrewardful.com/signup'
              target='_blank'
              rel='noopener noreferrer nofollow'
            >
              <div className='app-header__additional'>
                <span>{translate("Join our Affiliate Program")}</span> <span>-</span>
                <span>{translate("receive 30% from each paying customer referred by you")}</span>
              </div>
            </a>
          )}
          {isBlackFridayVisible && (
            <NavLink to='/render/pricing?tab=holiday-deal'>
              <div className={blackFridayClassNames}>
                {isMobile ? (
                  <div className='app-header__black-friday-mobile'>
                    <div style={{ fontSize: 13, fontWeight: 800 }}>33% off on all plans!</div>
                    <div style={{ fontSize: 18, fontWeight: 700, marginTop: -5 }}>
                      Black Friday Deal
                    </div>
                    <div style={{ fontSize: 10, fontWeight: 500, marginTop: -5 }}>
                      Secure the discounted rate for up to 3 months
                    </div>
                  </div>
                ) : (
                  <span>
                    <b>Black Friday Deal</b> - 33% off on all plans! Secure the discounted rate for
                    up to 3 months
                  </span>
                )}
                <div className='app-header__black-friday-button'>
                  <Button onClick={redirectToBlackFridayPage}>{translate("Get started")}</Button>
                </div>
              </div>
            </NavLink>
          )}
          {isMenuVisible && (
            <div
              className='app-header__content'
              onScroll={handleContentScroll}
              ref={headerRef}
            >
              <MobileNav
                renderMenu={renderMenu}
                isLoggedIn={!!loggedIn}
                showPremium={showPremium}
                onPricingClick={(e) => {
                  e.preventDefault();
                  onToggleTopMenu();
                  push('?scrollTo=pricing');
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
